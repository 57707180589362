import { reactive } from 'vue';
import { useField, useForm as useVeeValidateForm } from 'vee-validate';

export function useAppForm<T extends string>({
  fields,
  validationSchema,
}: {
  fields: T[];
  validationSchema: any;
}) {
  const { handleSubmit, errors, resetForm, validate } = useVeeValidateForm({
    validationSchema,
  });

  type FormType = {
    [K in T]: any;
  };

  const form = fields.reduce(
    (acc, fieldName) => {
      const { value } = useField(fieldName);

      acc[fieldName] = value;

      return acc;
    },
    reactive({}) as FormType,
  );

  return { form, handleSubmit, errors, resetForm, validate };
}
